exports = module.exports = require("../../node_modules/.pnpm/css-loader@1.0.1_webpack@4.46.0/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".vote-frame_frame_2OJUs {\n    width: 100%;\n    height: 128px;\n    border: 0;\n}", ""]);

// exports
exports.locals = {
	"frame": "vote-frame_frame_2OJUs"
};